import React from "react"
import styled from "styled-components"
import { Row, Col } from "react-styled-flexboxgrid"
import { ButtonGhostInverse } from "../Button"
import { Primary, Alternate } from "../Section"
import ServiceArea from "../../images/service-area1-298x300.jpg"

const Label = styled.label`
  display: block;
`
const Input = styled.input`
  width: 100%;
  background-color: ${props => props.theme.gallery};
  padding: 0.5rem;
  border-radius: 2px;
  display: block;
  margin-bottom: 1rem;
  border-style: solid;
`
const TextArea = styled.textarea`
  display: block;
  background-color: ${props => props.theme.gallery};
  border-style: solid;
  padding: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
`

const Contact = () => {
  return (
    <section>
      <Alternate centered backgroundHeight={350}>
        <h1>Contact</h1>
      </Alternate>
      <Primary>
        <Row>
          <Col xs={12} md={6}>
            <p>The Iceman</p>
            <p>
              <strong>E-Mail:</strong> snow@myiceman.com
            </p>
            <p>
              <strong>Phone:</strong> (770) 599-2862
            </p>
            {/* <br />
            <h3>
              Serving the entire<br></br> State of Georgia
            </h3>
            <img src={ServiceArea} alt="Serving the southwest United States" /> */}
          </Col>
          <Col xs={12} md={6}>
            <form
              name="contact"
              method="post"
              action="/thanks"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <Label>Your Name:* </Label>
              <Input type="text" name="name" id="name" required />
              <Label>Your Email:* </Label>
              <Input type="email" name="email" id="email" required />
              <Label>Message:* </Label>
              <TextArea name="message" id="message" />
              <ButtonGhostInverse type="submit" required>
                Send your message
              </ButtonGhostInverse>
            </form>
          </Col>
        </Row>
      </Primary>
    </section>
  )
}

export default Contact
